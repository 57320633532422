import React, { useState } from 'react';
import {
    Checkbox, Typography, Paper, TextField, Button,
    ThemeProvider, createTheme, Radio, RadioGroup, FormControlLabel
  } from '@mui/material';
import { useSnackbar } from 'notistack';




const theme = createTheme({
  palette: {
    primary: {
      main: '#5c6bc0',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      paper: 'rgba(255, 255, 255, 0.7)',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

function FormInvitation() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    mobile: '',
    referralSource: '',
    acceptedTerms: false
  });
  const [errors, setErrors] = useState({
    name: '',
    surname: '',
    email: '',
    mobile: '',
    referralSource: '',
    acceptedTerms: ''
  });
  //const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const validateMobile = (mobile) => {
    return /^\d{10}$/.test(mobile); // Validates a 10 digit number, adjust pattern as needed
  };

  const handleChange = (field, value) => {
    
    
    setFormData(prev => ({ ...prev, [field]: value }));
    setErrors(prev => ({ ...prev, [field]: '' }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let currentErrors = {};
    if (!formData.email || !validateEmail(formData.email)) {
      currentErrors.email = 'Por favor, introduce una dirección de correo electrónico válida.';
    }
    if (!formData.mobile) {
      currentErrors.mobile = 'Por favor, introduzca un número de móvil válido.';
    }
    if (!formData.name) {
      currentErrors.name = 'Se requiere el nombre.';
    }
    if (!formData.surname) {
      currentErrors.surname = 'El apellido es obligatorio.';
    }

    if (!formData.referralSource){
        currentErrors.referralSource = 'Por favor complete el siguiente campo.';
    }
    
    if (!formData.acceptedTerms) {
      currentErrors.acceptedTerms = 'debes aceptar el aviso de privacidad.';
    }

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors);
      return;
    }

    // Sending the valid form data to the server
    try {
        setIsSubmitting(true);
        const response = await fetch('https://script.google.com/macros/s/AKfycbyeYmMWd8ispW_Pvtnj6_uby_XxZCkwpjDy9BwHuELBsJEtf0P4C8m3m72g3x6IZAaemw/exec', {
          redirect: 'follow',
          method: 'POST',
          headers: {
            'Content-Type': "text/plain;charset=utf-8",
          },
          body: JSON.stringify(formData),
        });
  
        const result = await response.text();  // Handle JSON or other formats as needed
        if (response.ok) {
          enqueueSnackbar('Youre booked!', { variant: 'success' });
        } else {
          enqueueSnackbar(result || 'Something went wrong :(', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Network error: Unable to submit form', { variant: 'error' });
      }
      setIsSubmitting(false);
    
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper style={{ padding: 20, borderRadius: 10, maxWidth: 600, margin: 'auto' }}>
        <Typography variant="h5" component="h2" color="primary" gutterBottom>
            No te lo pierdas, rellena el formulario y ve calentando motores
        </Typography>
        <form noValidate autoComplete="off" style={{ display: 'flex', flexDirection: 'column', gap: '16px' }} onSubmit={handleSubmit}>
          <TextField
            id="name"
            label="nombre"
            variant="standard"
            fullWidth
            value={formData.name}
            onChange={(e) => handleChange('name', e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            id="surname"
            label="Apellidos"
            variant="standard"
            fullWidth
            value={formData.surname}
            onChange={(e) => handleChange('surname', e.target.value)}
            error={!!errors.surname}
            helperText={errors.surname}
          />
          <TextField
            id="email"
            label="Email"
            variant="standard"
            fullWidth
            value={formData.email}
            onChange={(e) => handleChange('email', e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            id="mobile"
            label="Telefono"
            variant="standard"
            fullWidth
            value={formData.mobile}
            onChange={(e) => handleChange('mobile', e.target.value)}
            error={!!errors.mobile}
            helperText={errors.mobile}
          />

          <Typography gutterBottom style={{ marginBottom: 0 }}>
          ¿cómo nos has conocido? 
            <RadioGroup row name="referralSource" value={formData.referralSource} onChange={(e) => handleChange('referralSource', e.target.value)}>
                <FormControlLabel value="social media" control={<Radio />} label="Social Media redes" />
                <FormControlLabel value="a friend" control={<Radio />} label="Un amigo" />
            </RadioGroup>
          </Typography>
            
            {errors.referralSource && (
            <Typography color="error">
                {errors.referralSource}
            </Typography>
            )}

          <Typography gutterBottom>
            He leído y acepto la política de privacidad <a href="https://docs.google.com/document/d/1xrgu7CTMTIXzxbSN6C_KRiJNBTazNIbf/edit">policy</a>:
            {/* <Checkbox checked={acceptedTerms} onChange={(e) => setAcceptedTerms(e.target.checked)} /> */}
            <Checkbox checked={FormData.acceptedTerms} onChange={(e) => handleChange('acceptedTerms', e.target.checked)} />

            {errors.acceptedTerms && (
            <Typography color="error" style={{ marginTop: 16 }}>
              {errors.acceptedTerms}
            </Typography>
          )}
          </Typography>
          


          <Button type="submit" disabled={isSubmitting} variant="contained" color="primary" style={{ marginTop: 16 }}>
            {isSubmitting
              ? 'registrar...'
              : '¡Reserve Ya!'}
          </Button>
          
        </form>
      </Paper>
    </ThemeProvider>
  );
}

export default FormInvitation;
