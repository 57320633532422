import React from 'react';
import { Typography, Paper, List, ListItem, ListItemText, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5c6bc0', // A soothing deep blue
    },
    secondary: {
      main: '#f48fb1', // A lively pink
    },
    background: {
      paper: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

function PartyInvitation() {
  return (
    <ThemeProvider theme={theme}>
      <Paper style={{ padding: 20, borderRadius: 10, maxWidth: 600, margin: 'auto' }}>
        <Typography variant="h5" component="h2" color="primary" gutterBottom>
          Hola fiesteros!
        </Typography>
        <Typography variant="body1" paragraph>
          – People you may es todo buen rollo entre amigos, los viejos y los nuevos. Así que para ello, elige a quién invitas y que quede entre nosotros.
        </Typography>
        <Typography variant="body1" paragraph>
          
            ¿Echas de menos un buen jolgorio y conocer gente de tu rollo? Pues ésta es la señal
        </Typography>

        <Typography variant="body1" paragraph style={{ marginBottom: 0 }}>
          Cositas importantes:
        </Typography>

        <List dense disablePadding>
          <ListItem>
            <ListItemText primary="Fecha: Domingo 5 de Mayo" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Dónde: Terraza Atenas" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Hora: 6pm-12AM" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Lineup: Diago - Adventureøsch - Mostly James" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Atmosphere: cócteles de autor, gente maja y un poco de house." />
          </ListItem>
        </List>
        <Typography variant="body1" paragraph style={{ marginBottom: 0 }}>
          Asegura tu sitio:
        </Typography>
        <List dense disablePadding>
          <ListItem>
            <ListItemText primary="Ser mayor de 18 y estar dispuesto a divertirte  ✨" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Repartir amor y respeto al resto de tus compis festivos" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Posiblemente convertirte en una estrella de las redes, puede que inmortalicemos el momento con alguna foto o vídeo" />
          </ListItem>
          <ListItem>
            <ListItemText primary="No te lo pierdas, rellena el formulario y ve calentando motores !" />
          </ListItem>
        </List>
      </Paper>
    </ThemeProvider>
  );
}

export default PartyInvitation;
