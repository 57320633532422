import logo from './assets/logo2.0.png';
import TextField from '@mui/material/TextField';


import PartyInvitation from './components/PartyInvitation';
import FormInvitation from './components/FormInvitation';
import {SnackbarProvider} from 'notistack';

function App() {
  return (
    <SnackbarProvider   autoHideDuration={5000} anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}>
      <div className="container">
        <div className="header">
          <img className="logo" src={logo} alt="Logo" /> 
        </div>
        <div className="main">
          
            <PartyInvitation />
            
            <FormInvitation />

          
          

        </div>
        
    </div>
  </SnackbarProvider>
  );
}

export default App;
